import request from '@/utils/request'
export function getListreleases (params = {}) {
  return request({
    url: '/releases',
    method: 'get',
    params
  })
}
export function getListReleases (id) {
  return request({
    url: '/releases/' + id,
    method: 'get'
  })
}
export function getPage (page, noitems) {
  return request({
    url: '/releases/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function getReleases (id) {
  return request({
    url: '/releases/' + id,
    method: 'get'
  })
}
export function storeReleases (data) {
  return request({
    url: '/releases',
    method: 'post',
    data
  })
}
export function updateReleases (_id, data) {
  return request({
    url: '/releases/' + _id,
    method: 'put',
    data
  })
}
export function destroyReleases (_id) {
  return request({
    url: '/releases/' + _id,
    method: 'delete'
  })
}
// export function getValidateBlog (data) {
//   return request({
//     url: '/blog/key',
//     method: 'post',
//     data
//   })
// }
export function search (data) {
  return request({
    url: '/releases/search',
    method: 'post',
    data
  })
}
export function exportExcel (data) {
  return request({
    url: '/releases/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
